export enum RouteLinks {
  HOME = "/",

  // Okta Links
  OKTA_ISSUER_DEFAULT = "/oauth2/default",

  // Admin Links
  ADMIN = "/admin",
  ADMIN_SIGNUP = "/admin/signup",
  ADMIN_LOGIN = "/admin/login",
  ADMIN_LOGIN_CALLBACK = "/admin/login/callback",
  // ADMIN_HOME = "/admin/home",

  // Admin Portal Links
  ADMIN_PORTAL = "/admin/portal",
  ADMIN_PORTAL_PERSONAL = "personal",
  ADMIN_PORTAL_EDUCATIONAL = "educational",
  ADMIN_PORTAL_PROFESSIONAL = "professional",
  ADMIN_PORTAL_ACHIEVEMENTS = "achievements",
  ADMIN_PORTAL_HOBBIES = "hobbies",

  PERSONAL_COMPONENT = "personal",
  EDUCATIONAL_COMPONENT = "educational",
  PROFESSIONAL_COMPONENT = "professional",
  ACHIEVEMENTS_COMPONENT = "achievements",
  HOBBIES_COMPONENT = "hobbies",
}
