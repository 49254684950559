import React, { useEffect, useMemo, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "boxicons/css/boxicons.min.css";
import "../assets/css/style.css";
import Header from "../components/AdminHeader";
import Footer from "../components/AdminFooter";
import { Outlet } from "react-router-dom";
import CvBuilderAdminService from "../../common/service/CvBuilderAdminService";
import { useOktaAuth } from "@okta/okta-react";
import { ProfileDetails } from "../model/ProfileDetails";
import { JourneyDetails } from "../model/JourneyDetails";

const AdminLayoutPage: React.FC = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const [profileDetails, setProfileDetails] = useState<ProfileDetails>();
  const journeyDetails = useMemo(() => ({} as JourneyDetails), []);

  useEffect(() => {
    const fetchData = async () => {
      const userInfo = await oktaAuth.getUser();
      if (accessToken && userInfo?.email) {
        CvBuilderAdminService.getResume(accessToken, userInfo.email)
          .then((response) => {
            if (response) {
              const responseProfileDetails =
                response.response as ProfileDetails;
              setProfileDetails(responseProfileDetails);
              // journeyDetails.journeyRequest = responseProfileDetails;
            }
          })
          .catch((error) => {
            console.error("Error occured:: ", error);
          });
      }
      // console.log(profileDetails);
    };

    if (authState?.isAuthenticated) {
      fetchData();
    }
  }, [authState]);

  return (
    <>
      <Header />
      <main id="main">
        <div className="container-fluid">
          <Outlet context={{ profileDetails, journeyDetails }} />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default AdminLayoutPage;
