import React from "react";
import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import HomePage from "./web/pages/HomePage";
import Redirect from "./admin/components/Redirect";
import { RouteLinks } from "./common/RouteLinks";
import AdminLayoutPage from "./admin/pages/AdminLayoutPage";
import { Security } from "@okta/okta-react";
import { ProtectedRoute } from "./admin/components/ProtectedRoute";
import OktaLogin from "./admin/components/OktaLogin";
import LoginCallback from "./admin/components/OktaCallback";
import OktaSignup from "./admin/components/OktaSignup";
import oktaAuth from "./config/oktaConfig";
import PersonalDetails from "./admin/components/PersonalDetailsComponent";
import Dashboard from "./admin/components/Dashboard";
import EducationalDetails from "./admin/components/EducationalDetails";
import ProfessionalDetails from "./admin/components/ProfessionalDetails";
import Achievements from "./admin/components/Achievements";
import Hobbies from "./admin/components/Hobbies";
import PersonalDetailsComponent from "./admin/components/PersonalDetailsComponent";

function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    console.log("restoreOriginalUri:: " + originalUri);
    navigate(originalUri || "/"); // Redirects after login
  };
  return (
    <>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Routes>
          {/* Web Route */}
          <Route path={RouteLinks.HOME} element={<HomePage />}></Route>

          {/* Admin Routes */}
          <Route path={RouteLinks.ADMIN} element={<Redirect />}></Route>
          <Route
            path={RouteLinks.ADMIN_SIGNUP}
            element={<OktaSignup />}
          ></Route>
          <Route path={RouteLinks.ADMIN_LOGIN} element={<OktaLogin />}></Route>
          <Route
            path={RouteLinks.ADMIN_LOGIN_CALLBACK}
            element={<LoginCallback />}
          />

          {/* Protected Admin Routes */}
          <Route
            path={RouteLinks.ADMIN_PORTAL}
            element={<ProtectedRoute component={AdminLayoutPage} />}
          >
            <Route index element={<Dashboard />} />
            <Route
              path={RouteLinks.PERSONAL_COMPONENT}
              element={<PersonalDetailsComponent />}
            />
            <Route
              path={RouteLinks.EDUCATIONAL_COMPONENT}
              element={<EducationalDetails />}
            />
            <Route
              path={RouteLinks.PROFESSIONAL_COMPONENT}
              element={<ProfessionalDetails />}
            />
            <Route
              path={RouteLinks.ACHIEVEMENTS_COMPONENT}
              element={<Achievements />}
            />
            <Route path={RouteLinks.HOBBIES_COMPONENT} element={<Hobbies />} />
          </Route>
        </Routes>
      </Security>
    </>
  );
}

export default App;
