import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ProfileDetails } from "../model/ProfileDetails";
import { RouteLinks } from "../../common/RouteLinks";
import { JourneyDetails } from "../model/JourneyDetails";

const Dashboard: React.FC = () => {
  const { profileDetails, journeyDetails } = useOutletContext<{
    profileDetails: ProfileDetails;
    journeyDetails: JourneyDetails;
  }>();
  const navigate = useNavigate();

  const handleBuildResumeEvent = (
    event: React.MouseEvent<HTMLInputElement>
  ) => {
    console.log("Build Resume Button Clicked");
    navigate(RouteLinks.ADMIN_PORTAL + "/" + RouteLinks.PERSONAL_COMPONENT);
  };

  return (
    <>
      {/* <h2 className="">Dashboard</h2> */}
      <div className="row">
        <div className="col-md-4 mt-5 mb-5">
          <div className="card">
            <div className="card-header">
              <h4>Welcome, {profileDetails?.personalDetails?.name}</h4>
            </div>
            <div className="card-body">
              <p>
                You are logged in as{" "}
                <b>{profileDetails?.personalDetails?.email}</b>
              </p>
              <input
                type="button"
                className="btn btn-primary"
                value="Build Your Resume"
                onClick={handleBuildResumeEvent}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
