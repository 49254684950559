import React, { useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { EducationalDetail, ProfileDetails } from "../model/ProfileDetails";

const EducationalDetails: React.FC = () => {
  const profileDetails = useOutletContext<ProfileDetails>();
  const [educationalDetails, setEducationalDetails] =
    useState<EducationalDetail>();
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const journeyDetails = location.state;

  return <div>EducationalDetails</div>;
};

export default EducationalDetails;
