import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";
import { RouteLinks } from "../../common/RouteLinks";
import loader from "../assets/img/spinner.webp";
import "./../../web/assets/css/style.css";

const LoginCallback: React.FC = () => {
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    const handleCallback = async () => {
      try {
        await oktaAuth.handleRedirect();
        navigate(RouteLinks.ADMIN_PORTAL); // Redirect user after login
      } catch (error) {
        console.error("Error handling authentication callback:", error);
      }
    };

    handleCallback();
  }, [oktaAuth, navigate]);

  return (
    <div className="container">
      <div className="row align-items-center login-row">
        <div className="mx-auto col-10 col-md-8 col-lg-6">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <img src={loader} alt="Loader" className="img-fluid" />
                </div>

                <div className="col-6 text-center">
                  <p className="lh-callback-text-loader">
                    <span>Processing...</span>
                    <br />
                    <span>Validating your request...!!!!</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginCallback;
