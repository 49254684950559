import axios from "axios";
import { Constants } from "../Constants";
import { ProfileDetails } from "../../admin/model/ProfileDetails";
import { ApiResponse } from "../../admin/model/ApiResponse";
import {
  JourneyDetails,
  SaveJourneyResponse,
} from "../../admin/model/JourneyDetails";

async function getResume(
  accessToken: string,
  email: string
): Promise<ApiResponse | null> {
  const response = await axios.get<ApiResponse>(
    `${Constants.PROD_URL}/secure/cv-builder-app/profile?q=${encodeURIComponent(
      email
    )}`,
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
  return response.data;
}

async function createResume(
  accessToken: string | undefined,
  profileDetails: ProfileDetails
) {
  return axios.post<ProfileDetails>(
    Constants.PROD_URL + "/secure/cv-builder-app/profile",
    profileDetails,
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
}

async function updateResume(
  accessToken: string | undefined,
  profileDetails: ProfileDetails
) {
  return axios.post<ProfileDetails>(
    Constants.PROD_URL + "/secure/cv-builder-app/profile",
    profileDetails,
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
}

async function saveJourney(
  accessToken: string | undefined,
  journeyDetails: JourneyDetails
) {
  return axios.post<SaveJourneyResponse>(
    Constants.PROD_URL + "/secure/common-data/journey/save",
    journeyDetails,
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
}

async function fetchJourney(
  accessToken: string | undefined,
  journeyDetails: JourneyDetails
) {
  return axios.post<ApiResponse>(
    Constants.PROD_URL + "/secure/common-data/journey/fetch",
    journeyDetails,
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
}

const CvBuilderAdminService = {
  getResume,
  createResume,
  updateResume,
  saveJourney,
  fetchJourney,
};

export default CvBuilderAdminService;
