import React, { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { RouteLinks } from "../../common/RouteLinks";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/img/logo.png";

const OktaLogin: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  React.useEffect(() => {
    const checkAuthentication = async () => {
      if (authState?.isAuthenticated) {
        console.log("User already authenticated, redirecting...");
        navigate(RouteLinks.ADMIN_PORTAL);
      }
    };

    checkAuthentication();
  }, [authState, navigate]);
  const onCancelEvent = () => {
    navigate(RouteLinks.HOME);
  };

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const transaction = await oktaAuth.signInWithCredentials({
        username,
        password,
      });
      console.log("transaction.status:: " + transaction.status);
      if (transaction.status === "SUCCESS") {
        console.log("transaction.sessionToken:: " + transaction.sessionToken);
        oktaAuth.signInWithRedirect({ sessionToken: transaction.sessionToken }); // Redirect user after login
      } else {
        setError("Login failed. Please check your credentials.");
      }
    } catch (err) {
      if (err instanceof Error) {
        setError("Login error: " + err.message);
      } else {
        setError("An unknown error occurred.");
      }
    }
  };

  return (
    <div className="container">
      <div className="row align-items-center login-row">
        <div className="mx-auto col-10 col-md-8 col-lg-6">
          <div className="text-center">
            <Link to={RouteLinks.HOME}>
              <img src={logo} alt="Logo" className="img-fluid admin-logo" />
            </Link>
          </div>
          <div className="card">
            <div className="card-body">
              <h1 className="text-center">Login</h1>
              {error && <p style={{ color: "red" }}>{error}</p>}
              <form onSubmit={handleLogin}>
                <div className="form-group mt-2">
                  <label className="form-label" htmlFor="email">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter your Email ID"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    autoFocus
                  />
                </div>
                <div className="form-group mt-2">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Enter the password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group mt-2">
                  <button type="submit" className="btn btn-primary float-start">
                    Login
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary float-end"
                    onClick={onCancelEvent}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OktaLogin;

// transaction:: {"data":{"expiresAt":"2025-02-06T01:32:01.000Z","status":"SUCCESS","sessionToken":"20111c-cSUF_pCzpxnPpSiu1vNXoDlPEGHHZjywnwdTZjwfu4NDHb_X","_embedded":{"user":{"id":"00u5mm6gkHoOD2Ido5d6","passwordChanged":"2021-02-10T13:14:23.000Z","profile":{"login":"lahasouvik@gmail.com","firstName":"Souvik","lastName":"Laha","locale":"en_US","timeZone":"America/Los_Angeles"}}},"_links":{"cancel":{"href":"https://dev-76718137.okta.com/api/v1/authn/cancel","hints":{"allow":["POST"]}}},"headers":{"cache-control":"no-cache, no-store","content-type":"application/json","expires":"0","pragma":"no-cache"}},"status":"SUCCESS","expiresAt":"2025-02-06T01:32:01.000Z","sessionToken":"20111c-cSUF_pCzpxnPpSiu1vNXoDlPEGHHZjywnwdTZjwfu4NDHb_X","headers":{"cache-control":"no-cache, no-store","content-type":"application/json","expires":"0","pragma":"no-cache"},"user":{"id":"00u5mm6gkHoOD2Ido5d6","passwordChanged":"2021-02-10T13:14:23.000Z","profile":{"login":"lahasouvik@gmail.com","firstName":"Souvik","lastName":"Laha","locale":"en_US","timeZone":"America/Los_Angeles"}}}
