import React from "react";
import logo from "../assets/img/logo.png";
import { Link, useLocation } from "react-router-dom";
import { RouteLinks } from "../../common/RouteLinks";
import "../assets/css/style.css";
import "bootstrap/dist/js/bootstrap.bundle";
import OktaLogout from "./OktaLogout";
import CvBuilderService from "../../common/service/CvBuilderCommonService";

const Header = () => {
  const location = useLocation();

  React.useEffect(() => {
    const healthCheck = async () => {
      await CvBuilderService.healthCheck().then((response) => {
        console.log("Health check response:: " + JSON.stringify(response.data));
      });
    };

    const interval = setInterval(healthCheck, 60000);

    healthCheck();

    return () => clearInterval(interval);
  }, []);

  // console.log(window.location.pathname);
  // console.log("location.pathname: ", location.pathname);

  return (
    <nav className="navbar navbar-expand-lg bg-primary" data-bs-theme="dark">
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <Link className="navbar-brand" to={RouteLinks.ADMIN_PORTAL}>
            <img src={logo} alt="Logo" />
            <span>&nbsp; Admin</span>
          </Link>
          <div className="border border-white logo-separator"></div>

          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                className={
                  location.pathname === RouteLinks.ADMIN_PORTAL
                    ? "nav-link active"
                    : "nav-link"
                }
                to={RouteLinks.ADMIN_PORTAL}
              >
                Dashboard
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                className={
                  location.pathname ===
                  RouteLinks.ADMIN_PORTAL + "/" + RouteLinks.PERSONAL_COMPONENT
                    ? "nav-link active"
                    : "nav-link"
                }
                to={RouteLinks.ADMIN_PORTAL_PERSONAL}
              >
                Personal
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  location.pathname ===
                  RouteLinks.ADMIN_PORTAL +
                    "/" +
                    RouteLinks.EDUCATIONAL_COMPONENT
                    ? "nav-link active"
                    : "nav-link"
                }
                to={RouteLinks.ADMIN_PORTAL_EDUCATIONAL}
              >
                Education
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  location.pathname ===
                  RouteLinks.ADMIN_PORTAL +
                    "/" +
                    RouteLinks.PROFESSIONAL_COMPONENT
                    ? "nav-link active"
                    : "nav-link"
                }
                to={RouteLinks.ADMIN_PORTAL_PROFESSIONAL}
              >
                Professional
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  location.pathname ===
                  RouteLinks.ADMIN_PORTAL +
                    "/" +
                    RouteLinks.ACHIEVEMENTS_COMPONENT
                    ? "nav-link active"
                    : "nav-link"
                }
                to={RouteLinks.ADMIN_PORTAL_ACHIEVEMENTS}
              >
                Achievements
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  location.pathname ===
                  RouteLinks.ADMIN_PORTAL + "/" + RouteLinks.HOBBIES_COMPONENT
                    ? "nav-link active"
                    : "nav-link"
                }
                to={RouteLinks.ADMIN_PORTAL_HOBBIES}
              >
                Hobbies
              </Link>
            </li> */}
          </ul>
        </div>
        <span className="navbar-text">
          {/* <Logout /> */}
          <OktaLogout />
        </span>
      </div>
    </nav>
  );
};

export default Header;
