import { useOktaAuth } from "@okta/okta-react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  component: React.ComponentType;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
}) => {
  const { authState } = useOktaAuth();

  return authState?.isAuthenticated ? <Component /> : <p>Access Denied</p>;
};
