import React from "react";
import CvBuilderService from "../../common/service/CvBuilderCommonService";
import { IContactUs } from "../model/request/IContactUs";

const Contact: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<string>();
  const [error, setError] = React.useState<string>();

  // const onChange = (event: { target: { name: any; value: any; }; }) => {
  //   setContactUs({...contactUs, [event.target.name]: event.target.value});
  // };
  const submitContactUs = (event: any) => {
    setLoading(true);
    event.preventDefault();
    const contactUs = {
      name: event.target.name.value,
      emailId: event.target.email.value,
      subject: event.target.subject.value,
      message: event.target.message.value,
    };

    console.log(contactUs);

    CvBuilderService.contactUs(contactUs as IContactUs)
      .then((response) => {
        setLoading(false);
        setStatus(response.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };
  return (
    <>
      {/* <!-- ======= Contact Section ======= --> */}
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Contact</h2>
          </div>

          <div className="row mt-1">
            <div className="col-lg-4">
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>Edinburgh, Scotland, United Kingdom</p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>lahasouvik@gmail.com</p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p>+44 7469 4382 00</p>
                </div>

                <div className="whatsapp">
                  <i className="bi bi-whatsapp"></i>
                  <h4>WhatsApp:</h4>
                  <p>+91 8100 5520 98</p>
                </div>
              </div>
            </div>

            <div className="col-lg-8 mt-5 mt-lg-0">
              <form
                onSubmit={submitContactUs}
                method="post"
                role="form"
                className="php-email-form"
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    id="message"
                    rows={5}
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
                <div className="my-3">
                  {loading && <div className="loading">Loading</div>}
                  {error && (
                    <div className="error-message">
                      Opps! Something went wrong
                    </div>
                  )}
                  {status && (
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  )}
                </div>
                <div className="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Contact Section --> */}
    </>
  );
};

export default Contact;
