import React, { ChangeEvent, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { PersonalDetails, ProfileDetails } from "../model/ProfileDetails";
import profileImage from "./../assets/img/default-profile-pic.jpg";
import { JourneyDetails } from "../model/JourneyDetails";
import { RouteLinks } from "../../common/RouteLinks";
import CvBuilderAdminService from "../../common/service/CvBuilderAdminService";
import { useOktaAuth } from "@okta/okta-react";
import { AuthState } from "@okta/okta-auth-js";

const PersonalDetailsComponent: React.FC = () => {
  const { profileDetails } = useOutletContext<{
    profileDetails: ProfileDetails;
  }>();
  const [journeyDetailsState, setJourneyDetailsState] =
    useState<JourneyDetails>({
      journeyId: "",
      journeyName: "",
      userEmail: "",
      submitted: false,
      journeyRequest: {
        personalDetails: {
          name: "",
          email: "",
          mobile: "",
          location: "",
          tagLine: "",
          summary: "",
          image: "",
        },
      },
    });
  // const [personalDetails, setPersonalDetails] = useState<PersonalDetails>();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isPreviewButtonEnabled, setIsPreviewButtonEnabled] = useState(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const { authState } = useOktaAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (profileDetails && authState) {
      // setPersonalDetails(profileDetails.personalDetails);

      fetchJourney(authState, profileDetails.personalDetails.email, false);
    } else {
      console.error("AuthState/profile is null");
    }
  }, [profileDetails, authState]);

  const handleChangeEvent = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;

    // setPersonalDetails((prevState) => {
    //   if (!prevState) return undefined; // Ensure it's defined
    //   return { ...prevState, [name]: value };
    // });

    setJourneyDetailsState((prevState) => ({
      ...prevState,
      journeyRequest: {
        ...prevState?.journeyRequest,
        personalDetails: {
          ...prevState?.journeyRequest?.personalDetails,
          [name]: value,
        },
      } as ProfileDetails,
    }));
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const result = reader.result as string;
        setPreviewImage(result);
        setIsPreviewButtonEnabled(true);
      };
    }
  };

  const handleSaveAndNextEvent = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setIsDisabled(!isDisabled);
    if (
      journeyDetailsState.journeyRequest &&
      journeyDetailsState.journeyRequest.personalDetails
    ) {
      journeyDetailsState.journeyRequest.personalDetails.image = previewImage
        ? previewImage
        : journeyDetailsState.journeyRequest.personalDetails.image;
    }
    const journey: JourneyDetails = {
      journeyId: journeyDetailsState.journeyId,
      journeyName: "RESUME_BUILDER_Personal_Details",
      userEmail: journeyDetailsState.journeyRequest?.personalDetails?.email,
      submitted: false,
      journeyRequest: journeyDetailsState.journeyRequest,
    };
    if (authState) {
      saveJourney(authState, journey);
    } else {
      console.error("AuthState is null");
    }

    // call the next link
    navigate(RouteLinks.ADMIN_PORTAL + "/" + RouteLinks.EDUCATIONAL_COMPONENT, {
      state: journey,
    });
  };

  const saveJourney = (authState: AuthState, journey: JourneyDetails) => {
    CvBuilderAdminService.saveJourney(
      authState?.accessToken?.accessToken,
      journey
    )
      .then((response) => {
        if (response) {
          journey.journeyId = response.data.response as string;
        }
      })
      .catch((error) => {
        console.error("Error occured:: ", error);
      });
  };

  const fetchJourney = (
    authState: AuthState,
    email: string,
    submitted: boolean
  ) => {
    let journeyDetails: JourneyDetails = {
      userEmail: email,
      submitted: submitted,
    };

    CvBuilderAdminService.fetchJourney(
      authState?.accessToken?.accessToken,
      journeyDetails
    )
      .then((response) => {
        if (response) {
          setJourneyDetailsState(response.data.response as JourneyDetails);
        }
        return journeyDetails;
      })
      .catch((error) => {
        console.error("Error occured:: ", error);
      });
  };

  return (
    <div className="row">
      {/* Profile Image */}
      <div className="col-md-2 col-lg-4 mt-4 mb-4 personal image-container">
        <img
          className="border rounded profile-pic"
          src={
            journeyDetailsState.journeyRequest?.personalDetails.image !== ""
              ? journeyDetailsState.journeyRequest?.personalDetails.image
              : profileImage
          }
          alt="profile pic"
          id="profilePic"
        />
        <input
          type="file"
          className="d-none"
          id="fileInput"
          accept="image/*"
          onChange={handleFileChange}
        />
        <div
          className="edit-icon"
          onClick={() => document.getElementById("fileInput")?.click()}
        >
          <i className="bi bi-pencil-fill"></i>
        </div>

        <input
          type="button"
          className={isPreviewButtonEnabled ? "preview" : "d-none"}
          value="preview"
          onClick={() => {
            const imgElement = document.getElementById(
              "profilePic"
            ) as HTMLImageElement;
            if (imgElement) {
              imgElement.src = previewImage;
            }
          }}
        />
        <input
          type="button"
          className={isPreviewButtonEnabled ? "cancel" : "d-none"}
          value="cancel"
          onClick={() => {
            const imgElement = document.getElementById(
              "profilePic"
            ) as HTMLImageElement;
            if (imgElement) {
              imgElement.src =
                journeyDetailsState.journeyRequest?.personalDetails?.image ||
                profileImage;
              setPreviewImage(
                journeyDetailsState.journeyRequest?.personalDetails?.image ||
                  profileImage
              );
            }
            setIsPreviewButtonEnabled(false);
          }}
        />
      </div>

      {/* Personal Data */}
      <div className="col-md-10 col-lg-8 mt-4 mb-4">
        <form className="row g-3">
          <div className="col-md-6">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={
                journeyDetailsState.journeyRequest?.personalDetails.name !== ""
                  ? journeyDetailsState.journeyRequest?.personalDetails.name
                  : ""
              }
              onChange={handleChangeEvent}
              disabled={isDisabled}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={
                journeyDetailsState.journeyRequest?.personalDetails.email !== ""
                  ? journeyDetailsState.journeyRequest?.personalDetails.email
                  : ""
              }
              onChange={handleChangeEvent}
              disabled={isDisabled}
            />
          </div>
          <div className="col-6">
            <label htmlFor="mobile" className="form-label">
              Mobile
            </label>
            <input
              type="text"
              className="form-control"
              id="mobile"
              placeholder="+919876543210"
              name="mobile"
              value={
                journeyDetailsState.journeyRequest?.personalDetails.mobile !==
                ""
                  ? journeyDetailsState.journeyRequest?.personalDetails.mobile
                  : ""
              }
              onChange={handleChangeEvent}
              disabled={isDisabled}
            />
          </div>
          <div className="col-6">
            <label htmlFor="location" className="form-label">
              Location
            </label>
            <input
              type="text"
              className="form-control"
              id="location"
              placeholder="City, District, Country"
              name="location"
              value={
                journeyDetailsState.journeyRequest?.personalDetails.location !==
                ""
                  ? journeyDetailsState.journeyRequest?.personalDetails.location
                  : ""
              }
              onChange={handleChangeEvent}
              disabled={isDisabled}
            />
          </div>

          <div className="col-md-12">
            <label htmlFor="tagLine" className="form-label">
              Tagline
            </label>
            <input
              type="text"
              className="form-control"
              id="tagLine"
              name="tagLine"
              value={
                journeyDetailsState.journeyRequest?.personalDetails.tagLine !==
                ""
                  ? journeyDetailsState.journeyRequest?.personalDetails.tagLine
                  : ""
              }
              onChange={handleChangeEvent}
              disabled={isDisabled}
            />
          </div>
          <div className="col-md-12">
            <label htmlFor="summary" className="form-label">
              Summary
            </label>
            <textarea
              className="form-control"
              id="summary"
              rows={5}
              name="summary"
              value={
                journeyDetailsState.journeyRequest?.personalDetails.summary !==
                ""
                  ? journeyDetailsState.journeyRequest?.personalDetails.summary
                  : ""
              }
              onChange={handleChangeEvent}
              disabled={isDisabled}
            ></textarea>
          </div>

          <div className="col-12">
            {/* Initially 'Update Profile' button will be enabled and rests will be disabled */}
            <button
              type="button"
              className={isDisabled ? "float-end btn btn-primary" : "d-none"}
              onClick={() => setIsDisabled(!isDisabled)}
            >
              <i className="bx bx-pencil"></i>&nbsp; Update Profile
            </button>
            <button
              type="button"
              className={
                isDisabled ? "float-start btn btn-secondary" : "d-none"
              }
              onClick={() => navigate(RouteLinks.ADMIN_PORTAL)}
            >
              <i className="bx bx-left-arrow-alt"></i>&nbsp; Back
            </button>
            {/* Initially 'Save' and 'Cancel' both will be disabled */}
            {/* If they are enabled then 'Update Profile' will be disabled */}
            <div className={!isDisabled ? "" : "d-none"}>
              <button
                type="button"
                className="float-end btn btn-success"
                onClick={handleSaveAndNextEvent}
              >
                Save & Next &nbsp; <i className="bx bx-right-arrow-alt"></i>
              </button>
              <button
                type="button"
                className="float-start btn btn-secondary"
                onClick={() => setIsDisabled(!isDisabled)}
              >
                <i className="bx bx-rotate-left"></i>&nbsp; Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalDetailsComponent;
